import React from "react";
import "./Cover.scss";

class Cover extends React.Component {
    
    render() {

      if (this.props.src) {
        return (
          <div dangerouslySetInnerHTML={{__html:`<div class="cover"><img src="./img/${this.props.src}" alt="${this.props.title}" /></div>`}}></div>
        )
      } else {
        return ('');
      }
    }
}

export default Cover;